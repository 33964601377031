@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url(/assets/fonts/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
}
@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url(/assets/fonts/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
}
@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url(/assets/fonts/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
}

/* SMOOTH_TO_SCROLL_TARGET_ANCHOR */
html {
  scroll-behavior: smooth;
}
/* OFFSET_TO_SCROLL_TARGET_ANCHOR */
:target {
  scroll-margin-top: 100px;
}

body {
  background-color: #fff !important;
  font-family: 'Poppins Regular' !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 28px !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  background-color: #fff;
  letter-spacing: normal !important;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 0.4em;
    height: 0.4em;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #6b6b6b;
    min-height: 24px;
    border: 3px solid #5e5e5e;
  }
  ::-webkit-scrollbar-thumb:focus {
    background-color: #959595;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: #959595;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #959595;
  }
  ::-webkit-scrollbar-corner {
    background-color: #2b2b2b;
  }
}

/* Jodit Editor - Custom Styles */
.jodit .jodit-popup__content {
  max-height: 400px;
}
.jodit .jodit-popup__content .jodit-custom-options {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.jodit .jodit-popup__content .jodit-custom-option {
  height: 2rem;
  padding: 0;
  border: none;
  background: none;
  border-radius: 4px;
  font-family: 'Poppins Regular';
  font-size: 0.875rem;
  cursor: pointer;
  transition: background 0.25s ease;
}
.jodit .jodit-popup__content .jodit-custom-option:hover {
  background-color: #f0f0f0;
}
.jodit .jodit-ui-form .jodit-ui-block:last-child {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 0.5rem;
}
.jodit .jodit-ui-form .jodit-ui-block:last-child button {
  width: 100%;
}
